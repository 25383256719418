

.App {
  text-align: center;
}



.App-header {
  height: 100;
  position: relative;
}

.nav-left{
  padding-top: 30px;
}

.App-header #nav-left a{
  text-align: left;
  padding-right: 40px;
  padding-top: 60px;
  text-decoration: none;
  font-family: 'Lalezar';
  font-size: 20px;
  color: #3c415f;
}

.App-header #nav-left a:hover{

  text-align: left;
  padding-right: 40px;
  font-family: 'Lalezar';
  font-size: 30px;
  color: whitesmoke;
  
  /* text-decoration: underline overline dotted whitesmoke ; */
}


.App-header #nav-right a{
  text-align: right;
  padding-right: 30px;
  padding-top: 60px;
  text-decoration: none;
  font-family: 'Lalezar';
  font-size: 20px;
  color: #3c415f;
}

.App-header #nav-right a:hover{
  text-align: right;
  padding-right: 30px;
  text-decoration: none;
  font-family: 'Lalezar';
  font-size: 30px;
  color: whitesmoke;
  /* text-decoration: dotted; */
}


.App Nav {
  padding-left: 15px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

#tsparticles{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0
}


.me-auto{
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.drawer Nav.link{
  text-decoration: none;
}



.drawer div{
  color: aliceblue;
  font-size: 40px;
  /* padding-bottom: 15px; */
  /* font-family: "Rock 3D"; */
  font-family: Lalezar;
}


.intro Box{
  text-align: left;
  position: fixed;
  padding-left: 400px;
  padding-top: 500px;
}

.btn{
  height: 100px;
}




