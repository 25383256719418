.experience{
    color:rgb(244, 242, 239);
    position: static;
    padding-top: 40px;
    padding-left: 300px;
    padding-right: 300px;
}

.exp_timeline b {
    padding-top: 10px;
    color: aliceblue;
}



.exp_timeline img{
    width: 50%;
}

.exp_timeline .smaller{
    width: 20%;
    padding-bottom: 13px;

}

.exp_timeline .aws{
    width: 30%;
    padding-bottom: 15px;
}

.exp_timeline .nyu{
    padding-bottom: 13px;
}

.white-text.ant-progress .ant-progress-text {
    color: aliceblue;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.exp_timeline Card{
    background-color: lightblue;
}

.exp_timeline .exp_title{
    font-style: italic;
}

.exp_timeline .exp_detail {
    padding-top: 13px;
    color: aliceblue;
}

.exp_detail div{
    padding-bottom: 5px;
}