
.project_overview{
    position: static;
    padding-left: 300px;
    padding-right: 300px;
    padding-top: 70px;
}

.project_overview h3{
    color: aliceblue;
    padding-top: 5px;
    font-size: 20px;
    font-family:Georgia, 'Times New Roman', Times, serif;
}

.project_overview p{
    font-size: 15px;
    color: gray;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.project_overview img{
    width: 80%;
}



